<template>
  <v-app>
    <v-app-bar
      app
    >
      <v-app-bar-nav-icon v-if="isLoggedIn" @click.stop="navDrawer = !navDrawer"></v-app-bar-nav-icon>
      <v-spacer />
      <user-info-head v-if="isLoggedIn"></user-info-head>
      <v-btn :to="{ name: 'login'}" text v-if="!isLoggedIn && $router.currentRoute.name !== 'login'">Login</v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="navDrawer"
      v-if="isLoggedIn"
      app
    >
      <navigation-list></navigation-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <template v-if="!loading">
          <router-view />
        </template>
        <template v-else>
          <spinner></spinner>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserInfoHead from './misc/UserInfoHead'
import NavigationList from './misc/NavigationList'
import Spinner from '@/components/Spinner'

export default {
  name: 'App',

  components: {
    UserInfoHead,
    NavigationList,
    Spinner
  },

  data: () => ({
    loading: false,
    navDrawer: false
  }),
  computed: {
    isLoggedIn () {
      return this.$store.getters['account/isLoggedIn']
    },
  },
  watch: {
    isLoggedIn (value) {
      if (value) {
        this.redirectToIntended()
      }
    }
  },
  mounted () {
    this.refreshLogin()
  },
  methods: {
    async refreshLogin () {
      this.loading = true
      await this.$store.dispatch('account/refresh')
      this.loading = false
    },
    redirectToIntended () {
      if (this.isLoggedIn) {
        this.$store.dispatch('redirectToIntendedRoute')
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>