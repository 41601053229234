<template>
  <v-card>
    <v-card-title>{{ $t('stations.title') }}</v-card-title>
    <v-card-text>
      <v-data-table
        :items="stations"
        :headers="headers"
        :loading="loading"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            :to="{ name: 'station/chart', params: { id: item.id } }"
            :title="$t('stations.button_chart')"
          >
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Stations',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    stations () {
      return this.$store.getters['stations/items']
    },
    headers () {
      return [
        {
          text: this.$t('general.name'),
          value: 'name'
        },
        {
          text: this.$t('stations.fields.uid'),
          value: 'uid'
        },
        {
          text: this.$t('general.actions'),
          value: 'actions',
          align: 'end',
          sortable: false,
          filterable: false,
        }
      ]
    }
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('stations/index')
    this.loading = false
  }
}
</script>
