import Vue from 'vue'
import VueI18n from 'vue-i18n'

// vuetify locales
import de from 'vuetify/lib/locale/de'

Vue.use(VueI18n)

const DEFAULT_LANG = 'de'

const locales = {
  de: {
    $vuetify: {
      ...de,
    },
    ...require('./i18n/de.json'),
  }
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: 'de',
  messages: locales,
})

export default i18n
