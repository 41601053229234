import StationService from '../../services/StationService'

const getDefaultState = () => {
  return {
    items: [],
    level: [],
    data: {},
    flowData: [],
    sensors: []
  }
}

const state = getDefaultState()

// getters
const getters = {
  items: state => state.items,
  data: state => state.data,
  level: state => state.level,
  sensors: state => state.sensors,
  flowData: state => state.flowData
}

// actions
const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  async index ({ commit }) {
    try {
      const response = await StationService.index()
      commit('items', response.data.stations)
    } catch (e) {
      console.error(e)
      commit('items', [])
    }
  },
  clearData({ commit }) {
    commit('data', {})
  },
  async data ({ commit }, payload) {
    try {
      const response = await StationService.data(payload.stationId, payload.date)
      commit('data', response.data)
    } catch(e) {
      console.error(e)
      commit('data', {})
    }
  },
  async sensors ({ commit }, payload) {
    try {
      const response = await StationService.sensors(payload.stationId, payload.type)
      commit('sensors', response.data.sensors)
    } catch (e) {
      console.error(e)
      commit('sensors', [])
    }
  }
}

// mutations
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  items (state, items) {
    state.items = items
  },
  data (state, data) {
    const tmp = {}

    tmp.level = data.level ?? []
    tmp.flowData = data.flow_data ?? []

    state.data = tmp
  },
  sensors (state, sensors) {
    state.sensors = sensors
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
