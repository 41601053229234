/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index'

import RouterContainer from './views/RouterContainer'
// import Home from './views/Home'
import Error403 from './views/Error403'

/* Stations */
import Stations from './views/stations/Stations'
import StationChart from './views/stations/StationChart'

/* Auth */
import Login from './views/auth/Login'
import ForgotPassword from './views/auth/ForgotPassword'
import ResetPassword from './views/auth/ResetPassword'
// import Register from './views/auth/Register'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // component: Home,
      redirect: { name: 'station/index' },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/403',
      name: 'error403',
      component: Error403
    },
    {
      path: '/stations',
      name: 'station',
      redirect: { name: 'station/index' },
      component: RouterContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'station/index',
          component: Stations
        },
        {
          path: ':id/chart',
          name: 'station/chart',
          component: StationChart,
          props: true
        }
      ]
    },
    {
      path: '/auth',
      component: RouterContainer,
      meta: {
        requiresAuth: false
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: {
            navtitle: 'Login'
          }
        },
        {
          path: 'forgot_password',
          name: 'forgotPassword',
          component: ForgotPassword,
          meta: {
            navtitle: 'Passwort vergessen'
          }
        },
        {
          path: 'reset_password/:token/:email',
          name: 'resetPassword',
          component: ResetPassword,
          props: true,
          meta: {
            navtitle: 'Neues Passwort setzen'
          }
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // check if the route requires authentication and user is not logged in
  if (to.matched.some(route => route.meta.requiresAuth) && !store.state.account.isLoggedIn) {
    // save intended route so the app can automatically redirect to it if refresh succeeds
    if (to.name !== 'login') {
      store.dispatch('intendedRoute', to)
    }
    next({ name: 'login' })
    return
  }

  next()
})

export default router
