import Vuex from 'vuex'
import Vue from 'vue'

import router from '../router'

/** modules */
import account from './modules/account'
import stations from './modules/stations'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  state: {
    intendedRoute: undefined,
  },
  mutations: {
    setIntendedRoute (state, route) {
      state.intendedRoute = route
    },
    unsetIntendedRoute (state) {
      state.intendedRoute = undefined
    },
  },
  actions: {
    reset ({ dispatch }) {
      dispatch('stations/resetState')
    },
    redirectToVerifyEmail ({ getters, commit }) {
      if (getters.intendedRoute && getters.intendedRoute.name === 'verifyEmail') {
        return
      }
      commit('setIntendedRoute', { name: 'verifyEmail' })
    },
    intendedRoute ({ commit }, route) {
      commit('setIntendedRoute', route)
    },
    resetIntendedRoute ({ commit }) {
      commit('unsetIntendedRoute')
    },
    redirectToIntendedRoute ({ getters, commit }) {
      const intendedRoute = getters.intendedRoute
      if (intendedRoute) {
        commit('unsetIntendedRoute')
        if (router.currentRoute.name !== intendedRoute.name) {
          router.push(intendedRoute)
        }
      } else {
        if (router.currentRoute.name !== 'home') {
          router.push({ name: 'home' })
        }
      }
    },
  },
  getters: {
    intendedRoute (state) {
      return state.intendedRoute
    },
  },
  modules: {
    account,
    stations
  },
})
