<template>
  <v-card>
    <v-card-title>{{ $t('stations.chart_title') }}</v-card-title>
    <v-card-text>
      <div class="d-flex justify-center align-end">
        <v-btn color="primary" class="mx-1" @click="() => prevDate()" :disabled="loading"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <date-picker class="mx-1" :style="{width: '120px'}" v-model="date" :disabled="loading" :clearable="false" :max="maxDate" :label="$t('general.date')"  />
        <v-btn color="primary" class="mx-1" @click="() => nextDate()" :disabled="isMaxDate || loading"><v-icon>mdi-chevron-right</v-icon></v-btn>
        <v-btn color="primary" class="mx-1" @click="() => loadData()" :disabled="loading"><v-icon>mdi-refresh</v-icon></v-btn>
      </div>
      <v-progress-linear indeterminate :active="loading" />
    </v-card-text>
    <v-divider class="my-3" />
    <v-card-text>
      <station-flow :date="date" />
    </v-card-text>
    <v-divider />
    <v-card-text>
      <station-level :date="date" />
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from '../../components/DatePicker.vue'
import StationFlow from './StationFlow.vue'
import StationLevel from './StationLevel.vue'

export default {
  components: {
    DatePicker,
    StationFlow,
    StationLevel
  },
  name: 'StationChart',
  props: ['id'],
  data () {
    const now = this.$moment().format('YYYY-MM-DD')
    return {
      date: now,
      maxDate: now,
      loading: false,
      chartSeries: []
    }
  },
  computed: {
    isMaxDate () {
      return this.date >= this.maxDate
    }
  },
  watch: {
    date () {
      this.loadData()
    }
  },
  async mounted () {
    this.$store.dispatch('stations/clearData')
    await this.$store.dispatch('stations/sensors', {
      stationId: this.id
    })
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loading = true
      await this.$store.dispatch('stations/data', {
        stationId: this.id,
        date: this.date
      })
      this.loading = false
    },
    nextDate () {
      if (this.isMaxDate) {
        return
      }
      this.date = this.$moment(this.date).add(1, 'day').format('YYYY-MM-DD')
    },
    prevDate () {
      this.date = this.$moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
    }
  }
}
</script>
