<template>
  <div>
    <v-dialog
      persistent
      ref="dialog"
      v-model="modal"
      width="350px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :clearable="clearable"
          :label="label"
          :readonly="readonly"
          :value="value"
          :disabled="disabled"
          append-outer-icon="mdi-calendar"
          @input="handleTextInput"
          @click:clear="clear"
          @click:append-outer="on.click"
          :rules="[validateTextInput].concat(rules)"
          :error-messages="errormessages"
          hide-details="auto"
        />
      </template>
      <v-card class="date-time-picker-card">
        <v-card-text>
          <v-date-picker :min="minDate" :max="maxDate" v-model="date" locale="de" first-day-of-week="1" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="cancel">Abbrechen</v-btn>
          <v-btn @click="save" :disabled="!valid">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DatePicker',
    props: {
      value: String,
      label: String,
      readonly: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      min: String,
      max: String,
      errormessages: {
        type: Array,
        default: function () {
          return []
        }
      },
      rules: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data () {
      return {
        modal: false,
        date: undefined
      }
    },
    computed: {
      valid () {
        return this.date !== undefined
      },
      maxDate () {
        if (this.max === undefined) return undefined
        const maxDT = this.$moment(this.max)
        return maxDT.isValid() ? maxDT.format('YYYY-MM-DD') : undefined
      },
      minDate () {
        if (this.min === undefined) return undefined
        const minDT = this.$moment(this.min)
        return minDT.isValid() ? minDT.format('YYYY-MM-DD') : undefined
      }
    },
    methods: {
      validateTextInput (value) {
        if (this.max !== undefined) {
          if (this.$moment(this.max).isBefore(value)) {
            return this.$t('rules.validateDateInput.afterMax', { max: this.max })
          }
        }
        if (this.min !== undefined) {
          if (this.$moment(this.min).isAfter(value)) {
            return this.$t('rules.validateDateInput.beforeMin', { min: this.min })
          }
        }
        return true
      },
      handleTextInput (value) {
        const dt = this.$moment(value)
        if (dt.isValid()) {
          this.$emit('input', dt.format('YYYY-MM-DD'))
          this.date = dt.format('YYYY-MM-DD')
        }
      },
      reset () {
        if (this.value) {
          const dt = this.$moment(this.value)
          this.date = dt.format('YYYY-MM-DD')
        } else {
          this.date = undefined
        }
      },
      cancel () {
        this.modal = false
      },
      clear () {
        this.$emit('input', undefined)
      },
      save () {
        if (this.valid) {
          this.$emit('input', `${this.date}`)
        }

        this.modal = false
      }
    },
    watch: {
      modal (open) {
        if (open) {
          this.reset()
        }
      }
    }
  }
</script>
