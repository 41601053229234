<template>
  <v-list
    expand
    dense
    nav
  >
    <v-list-item :to="{ name: 'home'}" exact>
      <v-list-item-title>{{ $t('nav.home') }}</v-list-item-title>
    </v-list-item>
    <v-list-item :to="{ name: 'station/index'}">
      <v-list-item-title>{{ $t('nav.stations') }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'NavigationList',
  data: () => ({}),
}
</script>
