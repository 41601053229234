<template>
  <div>
    <v-card-subtitle>{{ $t('stations.flow_title') }}</v-card-subtitle>
    <v-card-text>
      <apexchart
        type="line"
        height="400"
        :options="chartOptions"
        :series="chartSeries"
      />
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'StationFlow',
  props: ['date'],
  data () {
    return {
      chartSeries: []
    }
  },
  computed: {
    flowData () {
      return this.$store.getters['stations/data'].flowData ?? []
    },
    sensors () {
      const sensors = this.$store.getters['stations/sensors'] ?? []

      return sensors.filter(item => item.type?.name === 'flow')
    },
    chartOptions() {
      const date = this.$moment(this.date)
      return {
        chart: {
          animations: {
            enabled: false
          }
        },
        xaxis: {
          type: "datetime",
          min: date.startOf('day').valueOf(),
          max: date.endOf('day').valueOf(),
          labels: {
            datetimeUTC: false,
          },
        },
        yaxis: [
          {
            min: 0,
            max: 12,
            labels: {
              formatter: (val) => `${val.toFixed(2)} m³/h`,
            },
          },
        ],
        tooltip: {
          x: {
            format: "HH:mm",
          },
          y: {
            formatter:  this.yFormatter,
          },
        },
      };
    },
  },
  watch: {
    flowData () {
      this.prepareData()
    },
    sensors () {
      this.prepareData()
    }
  },
  methods: {
    prepareData () {
      const chartSeries = []
      const sensorData = {}

      if (this.sensors === undefined || !this.sensors.length) return

      for (const item of this.flowData) {
        const sensorId = item.sensor_id
        if (sensorData[sensorId] === undefined) {
          sensorData[sensorId] = []
        }

        sensorData[sensorId].push({
          x: item.datetime,
          y: item.flow
        })
      }

      for (const sensor of this.sensors) {
        const data = sensorData[sensor.id] ?? []

        if (data.length === 0) continue

        chartSeries.push({
          name: sensor.name,
          data
        })
      }

      this.chartSeries = chartSeries
    },
    yFormatter (value) {
      if (!value)  return '-'

      return `${value.toFixed(2)} m³/h`
    }
  }
}
</script>
