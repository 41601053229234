import Api from './Api'

export default {
  index () {
    return Api().get('/stations')
  },
  sensors (stationId, type) {
    return Api().get(`/stations/${stationId}/sensors`, { params: { type }})
  },
  data (stationId, date) {
    return Api().get(`/stations/${stationId}/data`, { params: { date } })
  },
}
