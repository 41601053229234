import i18n from '../lang/lang'

export default {
  required (value) {
    if (!value || value.length === 0) {
      return i18n.t('rules.required')
    }
    return true
  },
  validHexadecimal (value) {
    const pattern = /^([0-9A-Fa-f])+$/
    if (!value || !pattern.test(value)) {
      return i18n.t('rules.validHexadecimal')
    }

    return true
  },
  validEmail (value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!value || !pattern.test(value)) {
      return i18n.t('rules.validEmail')
    }
    return true
  },
  validOptionalEmail (value) {
    if (!value || value.length === 0) {
      return true
    }
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!value || !pattern.test(value)) {
      return i18n.t('rules.validOptionalEmail')
    }
    return true
  },
  validOptionalMobilePhone (value) {
    if (!value || value.length === 0) {
      return true
    }
    const pattern = /^(0043)\d+$/
    if (!pattern.test(value)) {
      return i18n.t('rules.validOptionalMobilePhone')
    }
    return true
  },
  validPassword (length) {
    return (value) => {
      if (!value || value.length < length) {
        return i18n.t('rules.validPassword', { length })
      }
      return true
    }
  },
  validOptionalPassword (length) {
    return (value) => {
      if (!value || value.length === 0) {
        return true
      }
      if (value.length < length) {
        return i18n.t('rules.validOptionalPassword', { length })
      }
      return true
    }
  },
  validInteger (value) {
    if (+value === parseInt(value)) {
      return true
    }
    return i18n.t('rules.validInteger')
  },
  validOptionalInteger (value) {
    if (!value || value.length === 0) {
      return true
    }
    if (+value === parseInt(value)) {
      return true
    }
    return i18n.t('rules.validOptionalInteger')
  },
  validOptionalFloat (value) {
    if (!value || value.length === 0) {
      return true
    }
    if (+value === parseFloat(value)) {
      return true
    }
    return i18n.t('rules.validOptionalFloat')
  },
  identicalValues (a, b, field) {
    if (a !== b) {
      return i18n.t('rules.identicalValues', { field })
    }
    return true
  },
  minLength (length) {
    return (value) => {
      if (!value || value.length < length) {
        return i18n.t('rules.minLength', { length })
      }
      return true
    }
  },
  maxLength (length) {
    return (value) => {
      if (!value || value.length > length) {
        return i18n.t('rules.maxLength', { length })
      }
      return true
    }
  },
  maxOptionalLength (length) {
    return (value) => {
      if (!value || value.length === 0) {
        return true
      }
      if (value.length > length) {
        return i18n.t('rules.maxOptionalLength', { length })
      }
      return true
    }
  },
  validTime (value) {
    if (!value || value.length === 0) {
      return true
    }
    const pattern = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
    if (!pattern.test(value)) {
      return i18n.t('rules.validTime')
    }
    return true
  },
  validRegex (pattern) {
    if (!pattern || pattern.length === 0) {
      return true
    }
    const parts = pattern.split('/')
    let regex = pattern
    let options = ''

    if (parts.length > 1) {
      regex = parts[1]
      options = parts[2]
    }

    try {
      RegExp(regex, options)
      return true
    } catch {
      return i18n.t('rules.validRegex')
    }
  }
}
